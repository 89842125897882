import React from 'react';

function MaintenanceBanner(){
    const divStyle = {
        width: '100%',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black',
        backgroundColor: '#f2fa14',
        padding: '1em 0em',
    };
    
    return(
        <div style={divStyle}>
            <p>The Implementation Development Map has moved here! {'>>> '} <a href="http://new.upk-improvement.org/idm" target="_blank">new.upk-improvement.org</a></p>
        </div>
    );
}

  export default MaintenanceBanner;
  